import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../models/user';
import { AutheService } from './authe.service';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { Resource } from '../models/resource';


@Injectable({
  providedIn: 'root'
})
export class ResourceService {

 baseUrl =  environment.apiUrl +  'Resource/';
 constructor(private http: HttpClient) { }

 getResources(resource): Observable<Resource[]> {
   return this.http.get<Resource[]>( this.baseUrl
  );
 }

 getResourcesByType(type): Observable<Resource[]> {
  return this.http.get<Resource[]>( this.baseUrl + 'GetResourcesByType/' + type 
 );
}


getResourcesBySection(section): Observable<Resource[]> {
  return this.http.get<Resource[]>( this.baseUrl + 'GetResourcesBySection/' + section 
 );
}

 AddResource(model: any) {
   return this.http.post(this.baseUrl  , model);
}

deleteResource(id: number) {
  return this.http.delete(this.baseUrl  + 'DeleteResource/' +  id);
}


}
