import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { EstudianteCalificaciones } from '../models/estudianteCalificaciones';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EstudianteService {

  baseUrl =  environment.apiUrl +  'Estudiante/';
  constructor(private http: HttpClient) { }

  updateEstudiante(model: any) {
    return this.http.post(this.baseUrl + 'register' , model , {headers: new HttpHeaders()
    .set('Content-Type', 'application/json')});
  }

  getCalificaciones(idEstudiante, idAsignatura): Observable<EstudianteCalificaciones[]> {
    return this.http.get<EstudianteCalificaciones[]>( environment.apiUrl + 'Calificaciones/'
     + idEstudiante + '/estudiante/' + idAsignatura
   );
  }

  registerAcceso(model: any) {
    return this.http.post(this.baseUrl + 'registerAcceso' , model);
 }

}
